<template>
  <div>
    <v-container class="v-container-fluid" fluid>
      <v-row>
        <v-col class="pb-0" cols="12" sm="6">
          <div>
            <div class="display-flex align-items-center">
              <p class="txt-title mb-0 mr-1 mon-bold">{{ texts.featuredSupplier.titleSection }}</p>
              <img width="25px" height="25px" src="@/assets/images/medalla.png" alt="">
            </div>
          </div>
        </v-col>
        <v-col class="pb-0" cols="12" sm="6">
          <div class="align-content">
            <v-btn to="/vendor" class="button-show-more pa-0 mon-medium" text>
              {{ texts.featuredSupplier.viewProviders }}
              <v-icon size="16px">
                mdi-chevron-right
              </v-icon>
            </v-btn>
            <!-- <v-btn
              class="carousel-button-prev ml-5"
              @click="$refs.buttonPrev.click()"
            >
              <v-icon
                size="18px"
                color="#73777E"
              >
                mdi-arrow-left
              </v-icon>
            </v-btn>
            <v-btn
              class="carousel-button-next ml-2"
              @click="$refs.buttonNext.click()"
            >
              <v-icon
                size="18px"
                color="#73777E"
              >
                mdi-arrow-right
              </v-icon>
            </v-btn> -->
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col v-if="loaderSkeleton">
          <home-featured-supplier-skeleton-layout />
        </v-col>
        <v-col v-else cols="12" sm="12">
          <div>
            <v-container class="px-0" fluid>
              <v-row>
                <v-col cols="12">
                  <div v-if="aPopularProviders.length > 0" class="container-carousel-providers">
                    <div v-for="(item, index) in aPopularProviders" :key="index"
                      @click="redirectVendorDetail(item.sVendorEnterpriseId)"
                      class="content-card-supplier display-flex align-items-center">
                      <div class="fixed-width">
                        <div class="content-image">
                          <img v-if="Object.keys(item.oVendorLogo.oImages).length > 0"
                            :src="item.oVendorLogo.oImages.sm" alt="" style="background-color:#ffffff;">
                          <img v-else src="@/assets/images/placeholder-image.jpeg" alt=""
                            style="background-color: #F4F4F4 ;">
                        </div>
                      </div>
                      <div class="resize-width">
                        <p class="txt-market mon-medium">{{ item.sName }}</p>
                        <div class="display-flex align-items-center mb-2">
                          <v-rating :value="item.dRating" length="5" color="#FFC92A" background-color="#C6C6C8" dense
                            small readonly></v-rating>
                          <p :style="item.dRating == 0 ? { color: '#C6C6C8' } : {}"
                            class="text-calif-num mon-bold ml-1 mb-n1">
                            {{
                            (item.dRating > 5)
                            ? 5
                            : item.dRating
                            }}
                          </p>
                        </div>
                        <div class="display-flex align-items-center mt-1">
                          <p class="text-orders mon-medium mr-2">{{ texts.featuredSupplier.textFinishedOrders }}</p>
                          <v-spacer style="border: 1px dashed #BABABA"></v-spacer>
                          <p class="text-orders-number mon-medium ml-2">{{ item.dFinishedOrders }}</p>
                        </div>
                      </div>
                    </div>
                    <!-- <carousel-component
                      :dots="false"
                      :nav="false"
                      :draggable="false"
                      :items="3"
                      style="position: relative"
                      :responsive="responsiveCarousel"
                    >
                      
                      <template slot="prev">
                        <span ref="buttonPrev"></span>
                      </template>
                      <template slot="next">
                        <span ref="buttonNext"></span>
                      </template>
                    </carousel-component> -->
                  </div>
                  <div v-else>
                    <p class="text-empty-home mon-regular">{{ texts.featuredSupplier.emptyText }}</p>
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  props: {
    texts: {
      type: Object,
      default: "",
    },
  },
  name: "HomeFeaturedSupplierLayout",
  data() {
    return {
      //VARIABLES
      responsiveCarousel: {
        0: { items: 1, nav: false },
        425: { items: 1, nav: false },
        600: { items: 1, nav: false },
        960: { items: 2, nav: false },
        1264: { items: 3, nav: false },
        1636: { items: 4, nav: false },
        1904: { items: 4, nav: false },
      },
      aPopularProviders: [],
      loaderSkeleton: true,
    };
  },
  beforeMount() {
    // this.$nextTick((e) => {
    this.getPopularProviders();
    // });
  },
  methods: {
    redirectVendorDetail: function (id) {
      this.$router.push(`/vendor/detail/${id}`);
    },
    getPopularProviders: function () {
      this.loaderSkeleton = true;

      DB.get(
        `${URI}/api/v1/${this.selectLanguage}/vendors/enterprises/rankings`,
        {
          headers: {
            Authorization: `Bearer ${this.$store.state.sToken}`,
          },
          params: {},
        }
      )
        .then((response) => {
          this.loaderSkeleton = false;

          this.aPopularProviders = response.data.results;
        })
        .catch((error) => {
          this.loaderSkeleton = false;

          this.mixError(error.response.data.message, error.response.status);
        });
    },
  },
  computed: {
    selectLanguage: function () {
      return this.$store.state.language;
    },
  },
  watch: {
    selectLanguage: function () {
      if (this.selectLanguage) {
        this.getPopularProviders();
      }
    },
  },
};
</script>

<style scoped>
.text-calif {
  text-align: right;
  font-size: 12px;
  letter-spacing: 0px;
  color: #151d27;
  opacity: 1;
  margin-bottom: 0px;
}

.text-calif-num {
  text-align: left;
  font-size: 12px;
  letter-spacing: 0px;
  color: #ffc92a;
  opacity: 1;
  margin-bottom: 0px;
}

.carousel-button-next,
.carousel-button-prev {
  min-width: 30px !important;
  height: 30px !important;
  background: #ffffff 0% 0% no-repeat padding-box !important;
  border-radius: 8px;
  opacity: 1;
  padding: 0px !important;
}

.txt-market {
  text-align: left;
  letter-spacing: 0px;
  color: #151d27;
  opacity: 1;
  font-size: 16px;
  margin-bottom: 5px;
}

.txt-calification {
  text-align: left;
  letter-spacing: 0px;
  color: #ffc92a;
  opacity: 1;
  font-size: 12px;
  margin-bottom: 0;
}

.txt-reviews {
  text-align: left;
  letter-spacing: 0px;
  color: #151d27;
  opacity: 1;
  font-size: 12px;
  margin-bottom: 0;
}

.text-response,
.text-response-time,
.text-orders,
.text-orders-number {
  text-align: left;
  font-size: 12px;
  letter-spacing: 0px;
  color: #bababa;
  opacity: 1;
  margin-bottom: 5px;
}

.txt-name {
  text-align: center;
  letter-spacing: 0px;
  color: #151d27;
  opacity: 1;
  font-size: 14px;
  margin-top: 5px;
}

.content-card-supplier:hover {
  background-color: #ffffff90;
  cursor: pointer;
}

.content-card-supplier {
  min-width: 340px;
  margin-right: 15px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 11px;
  opacity: 1;
  padding: 20px;
}

.fixed-width {
  width: 125px;
}

.resize-width {
  width: calc(100% - 125px);
}

.content-image {
  width: 110px;
  height: 110px;
  opacity: 1;
}

.content-image img {
  width: 100%;
  height: auto;
}

@supports (object-fit: contain) {
  .content-image img {
    height: 100%;
    object-fit: contain;
    object-position: center center;
    border: 1px solid #e8e8e8;
    border-radius: 19px;
  }
}

.button-show-more::before {
  background-color: transparent !important;
}

.align-content {
  text-align: right;
}

.button-show-more {
  color: #0071ff;
  text-transform: initial;
  font-size: 14px;
  letter-spacing: 0px;
  opacity: 1;
  text-decoration: underline;
}

.txt-title {
  text-align: left;
  letter-spacing: 0px;
  color: #3d414a;
  opacity: 1;
  font-size: 18px;
  font-weight: 600;
}

.container-carousel-providers {
  display: flex;
  overflow: auto;
  width: 100%;
}

@media (max-width: 960px) {
  .hidden-from-tablet {
    display: none;
  }
}

@media (max-width: 600px) {
  .align-content {
    text-align: left;
  }
}
</style>